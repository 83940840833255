<template>
    <div class="title-com" :style="{'margin-top':top+'px'}">
        <div class="line" :style="{'color':color[0]}"></div>
        <div class="box">
            <span class="til" :style="{'color':color[1]}">{{name}}</span>
            <span class="en" :style="{'color':color[2]}">{{eng}}</span>
        </div>
        <div class="line" :style="{'color':color[0]}"></div>
    </div>
</template>
<script>
export default {
    name: 'title-com',
    props:{
        top:{
            default: 40,
            type: Number
        },
        color:{
            default: () => ['#fff','#fff','#fff'],
            type: Array
        },
        name: {
            default: '',
            type: String
        },
        eng: {
            default: '',
            type: String
        }
    }
}
</script>
<style lang="scss" scoped>
.title-com{
    width: 100%;
    font-family: PingFang SC-Semibold, PingFang SC;
    display: inline-flex;
    justify-content: center;
    .line{
        width: 60px;
        height: 1px;
        border-bottom: 1px solid;
        padding-top: 34px;
    }
    .box{
        align-items: center;
        text-align: center;
        padding: 0 20px;
        .til{
            font-size: 40px;
            font-weight: 600;
            display: block;
        }
        .eg{
            display: block;
            font-size: 16px;
        }
    }
}
</style>