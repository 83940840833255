<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <banner/>
        <div class="case">
            <title-com name="技术方案" eng="TECHNICAL SOLUTION"/>
            <ul>
                <li v-for="(item, index) in jsList" :key="index">
                    <img :src="item.img" alt="" srcset="">
                    <p class="p1">{{item.name}}</p>
                    <p class="p2">{{item.des}}</p>
                </li>
            </ul>
        </div>
        <div class="serve">
            <img class="icon" src="../../assets/images/wenli-1.png" alt="" srcset="">
            <title-com name="服务案例" eng="SERVICE CASE" :color="['#ADB5BD', '#343A40', '#ADB5BD']" :top="62"/>
            <swiper class="banner-container"
                ref="imageSwiper"
                :options="imageSwiperOptions"
                @slide-change-transition-end="imageSwiperChange"
            >
                <swiper-slide class="banner-slide" v-for="(i, k) in swList" :key="k">
                <div class="banner-item">
                    <img :src="i.img" alt="" srcset="">
                    <div class="btn" @click="goto(i)">{{i.name}}</div>
                </div>
                </swiper-slide>
            </swiper>
            <!-- <div class="swiper-btn">
                <img @click="gotoPre" class="btn" src="../../assets/images/left.png" alt="" srcset="">
                <img @click="gotoNext" class="btn" src="../../assets/images/right.png" alt="" srcset="">
            </div> -->
        </div>
    </div>
</template>
<script>
import titleCom from '@/components/title.vue'
import banner from '@/components/banner.vue'
export default {
    components: {
        titleCom, banner
    },
    name: 'index',
    data(){
        return {
            jsList: [{
                img: require('../../assets/images/home01.png'),
                name: '产业元宇宙',
                des: '平行于物理世界的虚拟宇宙，利用沉浸式交互方式让现实与虚拟的人、物、环境都在元宇宙中实现交互，创造价值。'
            },{
                img: require('../../assets/images/home02.png'),
                name: '虚拟人',
                des: '虚拟人定位为新一代多模态人机交互系统，助力行业客户打造有智能、有形象、可交互的“数字分身”。'
            },{
                img: require('../../assets/images/home03.png'),
                name: 'NFT数字藏品',
                des: 'NFT即非同质化代币，拥有不可修改、可追溯的特征，可作为数字资产权益证明，推动数字资产私有化。'
            },],
            imageSwiperOptions: {
                slidesPerView: 3,
                centeredSlides: true,
            　　observer: true,
            　　observeParents: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                    // type: 'fraction',
                },
                autoHeight: true,
                loop: true
            },
            swList: [{
                name: '中食安泓瘦吧减脂元宇宙空间站',
                img: require('../../assets/images/case/case1.png'),
                href: 'https://external.333f.com/zhongshi-client/index',
            },{
                name: '广交会中国建设银行线上营业厅',
                img: require('../../assets/images/case/case2.png'),
                href: 'https://external.333f.com/online-CCB'
            },{
                name: '致同党建线上馆',
                img: require('../../assets/images/case/case8.png'),
                href: 'https://external.333f.com/zhitong-exhibition-new/#/'
            }],
            activeIndex: 1,
        }
    },
    methods: {
        goto(item){
            window.location.href = item.href
        },
        imageSwiperChange(){
            // console.log(this.$refs.imageSwiper.swiper.activeIndex)
            // this.activeIndex = this.$refs.imageSwiper.swiper.activeIndex;
        },
        gotoPre(){
            
            this.$refs.imageSwiper.swiper.slideTo(this.activeIndex-1, 300, true)
        },
        gotoNext(){
            this.$refs.imageSwiper.swiper.slideTo(this.activeIndex+1, 300, true)
        }
    }
}
</script>
<style lang="scss" scoped>
.page{
    .case{
        background: url('../../assets/images/bgjse.png');
        background-size: 100% 760px;
        height: 760px;
        ul{
            display: flex;
            justify-content: space-between;
            margin: 35px 182px 0;
            li{
                width: 450px;
                height: 550px;
                background: #FFFFFF;
                box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.16);
                border-radius: 16px;
                display: block;
                text-align: center;
                img{
                    width: 224px;
                    height: 224px;
                    margin-top: 56px;
                }
                .p1{
                    font-weight: 550;
                    color: #212529;
                    font-size: 26px;
                    margin-top: 32px;
                }
                .p2{
                    font-weight: 400;
                    color: #868E96;
                    font-size: 22px;
                    margin-top: 32px;
                    padding: 0 44px;
                    text-align: left;
                }
            }
        }
    }
    .serve{
        width: 1920px;
        position: relative;
        background: #fff;
        .icon{
            width: 711px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
        }
        .banner-container{
            width: 1556px;
            height: 864px;
            margin-top: 32px;
            .banner-slide{
                background: #fff;
                height: 638px;
                transition: 300ms;
                transform: scale(0.8);
                width: 506px;
                border-radius: 8px;
                margin-top: 32px;
                .banner-item{
                    text-align: center;
                    img{
                        width: 458px;
                        height: 486px;
                        margin: 32px 0;
                        border-radius: 5px;
                    }
                    .btn{
                        background: #F76000;
                        font-size: 24px;
                        color: #fff;
                        margin: 0 24px;
                        line-height: 56px;
                        border-radius: 16px;
                    }
                }
            }
            .swiper-slide-next{
                transform: scale(0.8);
                // opacity: .6;
            }
            .swiper-slide-prev{
                transform: scale(0.8);
                // opacity: .6;
            }
            .swiper-slide-active{
                background: #fff;//#7bbfea;
                // width: 253px!important;
                transition: 300ms;
                transform: scale(1);
                z-index: 999;
                box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
                border-radius: 8px;
            }
        }
        .swiper-btn{
            font-size: 16px;
            color: #495057;
            display: flex;
            align-items: center;
            position: absolute;
            top: 457px;
            z-index: 999;
            left: 182px;
            right: 182px;
            // width: 100%;
            text-align: center;
            justify-content: space-between;
            .btn{
                width: 48px;
                height: 48px;
            }
            .pagen{
                padding: 0 32px;
            }
        }
    }
}
</style>